import { createAsyncThunk } from '@reduxjs/toolkit'
import { Dashboard80Service } from '../Performances/service/Dashboard80Service'
import { BlockRequest } from '../Performances/models/Dashboard80Model'

const dashboard80Service = new Dashboard80Service()

export const getDashboardBlocks = createAsyncThunk(
    'performances/blocks',
    async () => {
        const data = await dashboard80Service.getDashboardBlocks()
        return data
    }
)

export const getBlockData = createAsyncThunk(
    'performances/blocks/data',
    async (params: { id: string; request: BlockRequest }) => {
        const data = await dashboard80Service.getBlockData(params.id, params.request)
        return data
    }
)

export const getCompanies = createAsyncThunk(
    'performances/filters/company',
    async () => {
        const data = await dashboard80Service.getCompanies()
        return data
    }
)

export const getZones = createAsyncThunk(
    'performances/filters/zones',
    async () => {
        const data = await dashboard80Service.getZones()
        return data
    }
)

export const getDevicesAndPlayers = createAsyncThunk(
    'performances/filters/devices-players',
    async () => {
        const data = await dashboard80Service.getDevicesAndPlayers()
        return data
    }
)

export const exportDashboardData = createAsyncThunk(
    'performances/blocks/export',
    async (params: { id: string; format: string; request: BlockRequest }) => {
        const data = await dashboard80Service.exportData(params.id, params.format, params.request)
        return data
    }
)
