import axios, { AxiosResponse, CancelTokenSource } from 'axios'
import mime from 'mime'
import { getSocietyId } from '../Hooks/useModeManagement'
import { User } from '../models/User'
import { CacheHandler } from './CacheHandler'
import SessionHandler from './SessionHandler'

type tEnv = {
    ApiRoot: string
    // Since lot-9
    ApiRoot2: string
    ApiRoot3: string // veriflix
    ApiTranslation: string
    ChunkSize?: number
    enableCookieEnv: boolean
}

export enum ApiRootType {
    one = 'one',
    two = 'two',
    three = 'three', // veriflix
    fullUrl = 'fullUrl' // The url is already complete, no base url to bind (ex: upload aws url)
}

const enableCookieEnv = process.env.REACT_APP_ENABLE_COOKIE_ENV === 'true'

export const Env: tEnv = {
    ApiRoot: enableCookieEnv && getCookie('REACT_APP_API_ROOT') ? getCookie('REACT_APP_API_ROOT') : process.env.REACT_APP_API_ROOT,
    ApiRoot2: enableCookieEnv && getCookie('REACT_APP_API_ROOT2') ? getCookie('REACT_APP_API_ROOT2') : process.env.REACT_APP_API_ROOT2,
    ApiRoot3: enableCookieEnv && getCookie('REACT_APP_API_ROOT3') ? getCookie('REACT_APP_API_ROOT3') : process.env.REACT_APP_API_ROOT3,
    ChunkSize:
        enableCookieEnv && getCookie('REACT_APP_CHUNK_SIZE')
            ? +getCookie('REACT_APP_API_ROOT2')
            : +process.env.REACT_APP_CHUNK_SIZE || 10485760,
    ApiTranslation: process.env.REACT_APP_API_TRANSLATION,
    enableCookieEnv
}

export const cacheableResponseHeaderName = 'X-Is-cacheable'
const headerCacheDurationName = 'api_cache_duration'
const cacheHandler = new CacheHandler(headerCacheDurationName)

type HeaderAction = {
    noAuthorization?: boolean
}

interface Header {
    'Content-Type'?: string
    Authorization?: string
    rootCompanyId?: string
    'X-Consumer-Id'?: string
}

export enum HttpMethod {
    'GET' = 'GET',
    'DELETE' = 'DELETE',
    'POST' = 'POST',
    'PUT' = 'PUT'
}

export interface progressEvent {
    total: number
    loaded: number
}

export interface PostParams {
    timeout?: number
    cancelToken?: string
    downloadFilename?: string
    onUploadProgress?: (e: progressEvent) => void
    proxyUrl?: string
    params?: Record<any, any>
    headers?: Header
    actions?: HeaderAction
}

export default class Http {
    static __instance: Http | null = null
    cancelTokenSources: Map<string, CancelTokenSource> = new Map()
    abortController = new AbortController()

    static defaultTimeout = 60000

    constructor() {
        if (Http.__instance) return Http.__instance
        Http.__instance = this
    }

    getToken() {
        return SessionHandler.getAccessToken() ?? ''
    }

    getToken2() {
        return SessionHandler.getAccessToken2() ?? ''
    }

    getToken3() {
        return SessionHandler.getAccessToken3() ?? ''
    }

    headers(apiRoot: ApiRootType, options?: Header, actions?: HeaderAction) {
        let header: Header = {
            'Content-Type': 'application/json',
            Authorization:
                apiRoot === ApiRootType.three
                    ? `Token ${this.getToken3()}`
                    : `Bearer ${apiRoot === ApiRootType.one ? this.getToken() : this.getToken2()}`,
            // #in-97
            'X-Consumer-Id': apiRoot === ApiRootType.three ? 'digiteka' : undefined
        }
        if (getSocietyId() !== null) {
            header['rootCompanyId'] = getSocietyId() as string
        }
        if (actions?.noAuthorization) delete header['Authorization']
        return { ...header, ...options }
    }

    getApiRoot(type: ApiRootType) {
        if (type === ApiRootType.one) return Env.ApiRoot
        if (type === ApiRootType.two) return Env.ApiRoot2
        return Env.ApiRoot3
    }

    /**Stream video */
    streamLink(videoId: string, apiRoot = ApiRootType.one) {
        return `${this.getApiRoot(apiRoot)}/stream/${videoId}`
    }

    post<T>(url: string, data: any, parameters?: PostParams, apiRoot = ApiRootType.one) {
        let timeout = parameters?.timeout ?? Http.defaultTimeout
        let cancelToken = parameters?.cancelToken ?? ''
        if (cancelToken.length > 0 && !this.cancelTokenSources.has(cancelToken)) {
            this.cancelTokenSources.set(cancelToken, axios.CancelToken.source())
        }
        const root = parameters?.proxyUrl ?? this.getApiRoot(apiRoot)
        return axios
            .post<T>(`${root}/${url}`, data, {
                headers: this.headers(apiRoot),
                onUploadProgress: parameters?.onUploadProgress,
                timeout,
                responseType: parameters?.downloadFilename ? 'blob' : 'json',
                cancelToken: this.cancelTokenSources.get(cancelToken)?.token,
                params: parameters?.params ?? {}
            })
            .catch(thrown => {
                return Promise.reject(thrown)
            })
            .then(response => {
                if ((parameters?.downloadFilename?.length ?? 0) > 0) {
                    this.handleDownload(response, parameters?.downloadFilename as string)
                }
                return Promise.resolve(response)
            })
    }

    patch<T>(url: string, data: any, parameters?: PostParams, apiRoot = ApiRootType.one) {
        let timeout = parameters?.timeout ?? Http.defaultTimeout
        let cancelToken = parameters?.cancelToken ?? ''
        if (cancelToken.length > 0 && !this.cancelTokenSources.has(cancelToken)) {
            this.cancelTokenSources.set(cancelToken, axios.CancelToken.source())
        }
        const root = parameters?.proxyUrl ?? this.getApiRoot(apiRoot)
        return axios
            .patch<T>(`${root}/${url}`, data, {
                headers: this.headers(apiRoot),
                onUploadProgress: parameters?.onUploadProgress,
                timeout,
                responseType: parameters?.downloadFilename ? 'blob' : 'json',
                cancelToken: this.cancelTokenSources.get(cancelToken)?.token,
                params: parameters?.params ?? {}
            })
            .catch(thrown => {
                return Promise.reject(thrown)
            })
            .then(response => {
                if ((parameters?.downloadFilename?.length ?? 0) > 0) {
                    this.handleDownload(response, parameters?.downloadFilename as string)
                }
                return Promise.resolve(response)
            })
    }

    get<T>(url: string, parameters?: PostParams, apiRoot = ApiRootType.one) {
        let timeout = parameters?.timeout ?? Http.defaultTimeout
        let cached = cacheHandler.getItem<T>(url)
        if (cached) {
            return Promise.resolve(cached)
        }
        let cancelToken = parameters?.cancelToken ?? ''
        if (cancelToken.length > 0 && !this.cancelTokenSources.has(cancelToken)) {
            this.cancelTokenSources.set(cancelToken, axios.CancelToken.source())
        }
        return axios
            .get<T>(`${this.getApiRoot(apiRoot)}/${url}`, {
                headers: this.headers(apiRoot),
                timeout,
                responseType: parameters?.downloadFilename ? 'blob' : 'json',
                cancelToken: this.cancelTokenSources.get(cancelToken)?.token,
                params: parameters?.params
            })
            .then(response => {
                if ((parameters?.downloadFilename?.length ?? 0) > 0) {
                    this.handleDownload(response, parameters?.downloadFilename as string)
                }
                cacheHandler.setItem(url, response)
                response.headers[cacheableResponseHeaderName] = 'true'
                return Promise.resolve(response)
            })
            .catch(e => Promise.reject(e))
    }

    download(url: string, parameters: PostParams, apiRoot = ApiRootType.one) {
        if (parameters?.downloadFilename?.length === 0) {
            return Promise.reject({ message: 'Wrong parameters. Filename required' })
        }
        let timeout = parameters?.timeout ?? Http.defaultTimeout
        return axios
            .get(`${this.getApiRoot(apiRoot)}/${url}`, { headers: this.headers(apiRoot), timeout: timeout, responseType: 'blob' })
            .then(response => {
                const extension = mime.getExtension(response.headers['content-type'])
                const filename = `${parameters.downloadFilename}.${extension}`
                this.handleDownload(response, filename)
                response.headers[cacheableResponseHeaderName] = 'true'
                return Promise.resolve(response)
            })
    }

    downloadV2(url: string, apiRoot = ApiRootType.one, encryptToken: string | null) {
        return this.downloadWithIframe(`${this.getApiRoot(apiRoot)}/${url}?token=${encryptToken}`)
    }

    downloadWithIframe(url: string) {
        return new Promise((resolve, _reject) => {
            let iframe = document.createElement('iframe')
            iframe.id = `id_${url}`
            iframe.src = url
            iframe.style.display = 'none'
            document.body.appendChild(iframe)
            setTimeout(() => {
                iframe.remove()
            }, 5000)
            resolve(true)
        })
    }

    downloadWithHeaders(
        url: string,
        apiRoot = ApiRootType.one,
        encryptToken: string | null,
        title: string,
        onProgress?: (progress: number) => void,
        parameters?: PostParams
    ) {
        const headers = { ...this.headers(apiRoot), Authorization: `${encryptToken}` }
        let cancelToken = parameters?.cancelToken ?? ''
        if (cancelToken.length > 0 && !this.cancelTokenSources.has(cancelToken)) {
            this.cancelTokenSources.set(cancelToken, axios.CancelToken.source())
        }
        return axios
            .get(`${this.getApiRoot(apiRoot)}/${url}`, {
                headers,
                responseType: 'blob',
                onDownloadProgress: progressEvent => {
                    if (onProgress) {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        onProgress(progress)
                    }
                },
                cancelToken: this.cancelTokenSources.get(cancelToken)?.token
            })
            .then(response => {
                const extension = mime.getExtension(response.headers['content-type'])
                const filename = `${title}.${extension}`
                this.handleDownload(response, filename)
                return Promise.resolve(response)
            })
    }

    downloadImage(urlParam: string, parameters: PostParams) {
        if (parameters?.downloadFilename?.length === 0) {
            return Promise.reject({ message: 'Wrong parameters. Filename required' })
        }
        axios
            .get(urlParam, {
                responseType: 'blob'
            })
            .then(response => {
                const fileName = 'vignette_' + parameters?.downloadFilename + '.jpg'
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(url)
            })
            .catch(e => console.log(e))
    }

    sendGet<T>(url: string, parameters?: any, apiRoot = ApiRootType.one) {
        return axios
            .get<T>(`${this.getApiRoot(apiRoot)}/${url}`, {
                headers: this.headers(apiRoot),
                timeout: Http.defaultTimeout,
                responseType: 'json',
                params: parameters
            })
            .then(response => {
                response.headers[cacheableResponseHeaderName] = 'true'
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }

    put<T>(url: string, data: any, timeout = Http.defaultTimeout, parameters?: PostParams, apiRoot = ApiRootType.one) {
        let cancelToken = parameters?.cancelToken ?? ''
        if (cancelToken.length > 0 && !this.cancelTokenSources.has(cancelToken)) {
            this.cancelTokenSources.set(cancelToken, axios.CancelToken.source())
        }

        return axios.put<T>(apiRoot === ApiRootType.fullUrl ? url : `${this.getApiRoot(apiRoot)}/${url}`, data, {
            headers: this.headers(apiRoot, parameters?.headers, parameters?.actions),
            timeout,
            cancelToken: this.cancelTokenSources.get(cancelToken)?.token,
            onUploadProgress: parameters?.onUploadProgress,
            params: parameters?.params ?? {} // TODO: to check
        })
    }

    delete(url: string, timeout = Http.defaultTimeout, apiRoot = ApiRootType.one) {
        return axios.delete(`${this.getApiRoot(apiRoot)}/${url}`, { headers: this.headers(apiRoot), timeout: timeout })
    }

    /**
     * Execute a http request
     * @param url URL
     * @param data Data
     * @param method HTTP method
     */
    send(url: string, data: any, method: HttpMethod): Promise<AxiosResponse> {
        if (method === 'GET') return this.get(url)
        if (method === 'DELETE') return this.delete(url)
        if (method === 'POST') return this.post(url, data)
        if (method === 'PUT') return this.put(url, data)
        return new Promise((resolve, reject) => {
            reject('Undefined Method')
        })
    }

    /**
     * abort specifique query
     * @param key key
     */
    abortById(key: string) {
        if (this.cancelTokenSources.get(key)) {
            this.cancelTokenSources.get(key)?.cancel()
            this.cancelTokenSources.delete(key)
        }
    }

    /**
     * Abort all running queries
     * @param message Message
     */
    abort(message?: string) {
        this.cancelTokenSources.forEach((token, _key) => {
            token.cancel(message)
        })
        this.cancelTokenSources.clear()
    }

    handleDownload(response: AxiosResponse, filename: string) {
        var url = window.URL.createObjectURL(new Blob([response.data]))
        var a = document.createElement('a')
        a.href = url
        document.body.appendChild(a)
        if (filename) a.download = filename
        a.click()
        a.remove()
    }

    handleDownloadDeprecated(response: any, filename: string) {
        response.blob().then((blob: any) => {
            var url = window.URL.createObjectURL(blob)
            var a = document.createElement('a')
            a.href = url
            document.body.appendChild(a)
            if (filename) a.download = filename
            a.click()
            a.remove()
        })
    }

    postDeprecated<T>(api: string, data: any, apiRoot = ApiRootType.one): Promise<T> {
        if (this.abortController.signal.aborted) {
            this.abortController = new AbortController()
        }
        return new Promise((resolve, reject) => {
            fetch(`${this.getApiRoot(apiRoot)}/${api}`, {
                method: 'POST',
                cache: 'no-cache',
                headers: this.headers(apiRoot) as any,
                redirect: 'follow',
                referrerPolicy: 'origin',
                body: JSON.stringify(data)
            })
                .then(response => {
                    if (!response.ok) {
                        reject(response)
                    } else {
                        response
                            .json()
                            .then(json => resolve(json))
                            .catch(err => reject(err))
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    getDeprecated(api: string, params?: Record<string, string>, apiRoot = ApiRootType.one): Promise<User> {
        var url = new URL(`${this.getApiRoot(apiRoot)}/${api}`)
        if (params) {
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        }
        return new Promise((resolve, reject) => {
            fetch(url.toString(), {
                method: 'GET',
                cache: 'no-cache',
                headers: this.headers(apiRoot) as any
            })
                .then(response => {
                    if (!response.ok) reject(response)
                    else {
                        response
                            .json()
                            .then(json => resolve(json))
                            .catch(err => reject(err))
                    }
                })
                .catch(err => reject(err))
        })
    }

    putDeprecated(api: string, data: any, apiRoot = ApiRootType.one) {
        return new Promise((resolve, reject) => {
            fetch(`${this.getApiRoot(apiRoot)}/${api}`, {
                method: 'PUT',
                cache: 'no-cache',
                headers: this.headers(apiRoot) as any,
                redirect: 'follow',
                referrerPolicy: 'origin',
                body: JSON.stringify(data)
            })
                .then(response => {
                    if (!response.ok) {
                        reject(response)
                    } else {
                        response
                            .json()
                            .then(json => resolve(json))
                            .catch(err => reject(err))
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    deleteDeprecated(api: string, params: Record<string, string> | null, apiRoot = ApiRootType.one) {
        var url = new URL(`${this.getApiRoot(apiRoot)}/${api}`)
        if (params) {
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        }
        return new Promise((resolve, reject) => {
            fetch(url.toString(), {
                method: 'DELETE',
                cache: 'no-cache',
                headers: this.headers(apiRoot) as any
            })
                .then(response => {
                    if (!response.ok) reject(response)
                    else
                        response
                            .json()
                            .then(json => resolve(json))
                            .catch(err => reject(err))
                })
                .catch(err => reject(err))
        })
    }
}

export function getCookie(key: string): string {
    return (
        document.cookie
            .split('; ')
            .find(row => row.startsWith(`${key}=`))
            ?.split('=')[1] ?? ''
    )
}
