import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { FetchStatusType } from '../storeType'
import {
    getDashboardBlocks,
    getBlockData,
    getCompanies,
    getZones,
    getDevicesAndPlayers,
    exportDashboardData
} from '../../Actions/Dashboard80Action'
import {
    BlockRequest,
    BlockResponse,
    CompaniesResponse,
    DashboardBlock,
    DevicesAndPlayersResponse,
    SwitchValue,
    ZonesResponse
} from '../../Performances/models/Dashboard80Model'
import { tRangePeriod } from '../../models/DateRange'
import { Range } from 'react-date-range'

const defaultRange = {
    key: 'range',
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: new Date()
}
interface Dashboard80State {
    loadingBlocks: FetchStatusType
    loadingBlockData: { [key: number]: FetchStatusType }
    loadingCompanies: FetchStatusType
    loadingZones: FetchStatusType
    loadingDevicesPlayers: FetchStatusType
    loadingExport: FetchStatusType
    dashboardBlocks: DashboardBlock[]
    blockData: { [key: number]: BlockResponse | null }
    companies: CompaniesResponse | null
    zones: ZonesResponse | null
    devicesAndPlayers: DevicesAndPlayersResponse | null
    currentBlockId: number | null
    currentBlockRequest: BlockRequest | null
    switchInventoryRate: SwitchValue
    switchInventory: SwitchValue
    selectedCompanies: string[]
    periodId1: tRangePeriod
    periodId2: tRangePeriod
    periodId3: tRangePeriod
    periodId4: tRangePeriod
    periodId5: tRangePeriod
    periodId6: tRangePeriod
    rangeId1: Range
    rangeId2: Range
    rangeId3: Range
    rangeId4: Range
    rangeId5: Range
    rangeId6: Range
    sortByBlock1: string
    sortByBlock2: string
    orderBlock1: 'asc' | 'desc'
    orderBlock2: 'asc' | 'desc'
    sortColumnBlock1: number
    sortColumnBlock2: number
    switchSeconde: SwitchValue
    companiesChecked: number[]
    rowsPageBlock1: number
    pageBlock1: number
    rowsPageBlock6: number
    pageBlock6: number
    sortByBlock6: string
    orderBlock6: 'asc' | 'desc'
    sortColumnBlock6: number
    zonesChecked: string[]
    devicesChecked: string[]
    playersChecked: string[]
}

const initialState: Dashboard80State = {
    devicesChecked: [],
    playersChecked: [],
    loadingBlocks: FetchStatusType.IDLE,
    loadingBlockData: {},
    loadingCompanies: FetchStatusType.IDLE,
    loadingZones: FetchStatusType.IDLE,
    loadingDevicesPlayers: FetchStatusType.IDLE,
    loadingExport: FetchStatusType.IDLE,
    dashboardBlocks: [],
    blockData: {},
    companies: null,
    zones: null,
    devicesAndPlayers: null,
    currentBlockId: null,
    currentBlockRequest: null,
    switchInventoryRate: 'sound',
    switchInventory: 'rate',
    selectedCompanies: [],
    periodId1: '-15d',
    periodId2: '-15d',
    periodId3: '-15d',
    periodId4: '-15d',
    periodId5: '-15d',
    periodId6: '-15d',
    rangeId1: defaultRange,
    rangeId2: defaultRange,
    rangeId3: defaultRange,
    rangeId4: defaultRange,
    rangeId5: defaultRange,
    rangeId6: defaultRange,
    sortByBlock1: 'inventory-sound-80-80',
    sortByBlock2: 'inventory',
    orderBlock1: 'desc',
    orderBlock2: 'desc',
    sortColumnBlock1: -1,
    sortColumnBlock2: -1,
    switchSeconde: '20sec',
    companiesChecked: [],
    rowsPageBlock1: 20,
    pageBlock1: 0,
    rowsPageBlock6: 20,
    pageBlock6: 0,
    sortByBlock6: 'inventory-sound-80-80',
    orderBlock6: 'desc',
    sortColumnBlock6: -1,
    zonesChecked: []
}

export const Dashboard80Slice = createSlice({
    name: 'dashboard80',
    initialState,
    reducers: {
        setZonesChecked(state, action: PayloadAction<string[]>) {
            return { ...state, zonesChecked: action.payload }
        },
        setDevicesChecked(state, action: PayloadAction<string[]>) {
            return { ...state, devicesChecked: action.payload }
        },
        setPlayersChecked(state, action: PayloadAction<string[]>) {
            return { ...state, playersChecked: action.payload }
        },
        setRowsPageBlock1(state, action: PayloadAction<number>) {
            return { ...state, rowsPageBlock1: action.payload }
        },
        setPageBlock1(state, action: PayloadAction<number>) {
            return { ...state, pageBlock1: action.payload }
        },
        setRowsPageBlock6(state, action: PayloadAction<number>) {
            return { ...state, rowsPageBlock6: action.payload }
        },
        setPageBlock6(state, action: PayloadAction<number>) {
            return { ...state, pageBlock6: action.payload }
        },
        setswitchInventoryRate(state, action: PayloadAction<SwitchValue>) {
            return { ...state, switchInventoryRate: action.payload }
        },
        setswitchInventory(state, action: PayloadAction<SwitchValue>) {
            return { ...state, switchInventory: action.payload }
        },
        setSelectedCompaniesPerf(state, action: PayloadAction<string[]>) {
            return { ...state, selectedCompanies: action.payload }
        },
        setPeriodBlock1(state, action: PayloadAction<tRangePeriod>) {
            return { ...state, periodId1: action.payload }
        },
        setPeriodBlock6(state, action: PayloadAction<tRangePeriod>) {
            return { ...state, periodId6: action.payload }
        },
        setRangeBlock1(state, action: PayloadAction<Range>) {
            return { ...state, rangeId1: action.payload }
        },
        setPeriodBlock2(state, action: PayloadAction<tRangePeriod>) {
            return { ...state, periodId2: action.payload }
        },
        setRangeBlock2(state, action: PayloadAction<Range>) {
            return { ...state, rangeId2: action.payload }
        },
        setPeriodBlock3(state, action: PayloadAction<tRangePeriod>) {
            return { ...state, periodId3: action.payload }
        },
        setRangeBlock3(state, action: PayloadAction<Range>) {
            return { ...state, rangeId3: action.payload }
        },
        setPeriodBlock4(state, action: PayloadAction<tRangePeriod>) {
            return { ...state, periodId4: action.payload }
        },
        setRangeBlock4(state, action: PayloadAction<Range>) {
            return { ...state, rangeId4: action.payload }
        },
        setPeriodBlock5(state, action: PayloadAction<tRangePeriod>) {
            return { ...state, periodId5: action.payload }
        },
        setRangeBlock5(state, action: PayloadAction<Range>) {
            return { ...state, rangeId5: action.payload }
        },
        setRangeBlock6(state, action: PayloadAction<Range>) {
            return { ...state, rangeId6: action.payload }
        },
        setSortByBlock1(state, action: PayloadAction<string>) {
            return { ...state, sortByBlock1: action.payload }
        },
        setSortByBlock2(state, action: PayloadAction<string>) {
            return { ...state, sortByBlock2: action.payload }
        },
        setOrderBlock1(state, action: PayloadAction<'asc' | 'desc'>) {
            return { ...state, orderBlock1: action.payload }
        },
        setOrderBlock2(state, action: PayloadAction<'asc' | 'desc'>) {
            return { ...state, orderBlock2: action.payload }
        },
        setSortColumnBlock1(state, action: PayloadAction<number>) {
            return { ...state, sortColumnBlock1: action.payload }
        },
        setSortColumnBlock2(state, action: PayloadAction<number>) {
            return { ...state, sortColumnBlock2: action.payload }
        },
        setSortByBlock6(state, action: PayloadAction<string>) {
            return { ...state, sortByBlock6: action.payload }
        },
        setOrderBlock6(state, action: PayloadAction<'asc' | 'desc'>) {
            return { ...state, orderBlock6: action.payload }
        },
        setSortColumnBlock6(state, action: PayloadAction<number>) {
            return { ...state, sortColumnBlock6: action.payload }
        },
        setSwitchSeconde(state, action: PayloadAction<SwitchValue>) {
            return { ...state, switchSeconde: action.payload }
        },
        setCompaniesChecked(state, action: PayloadAction<number[]>) {
            return { ...state, companiesChecked: action.payload }
        },
        setDefaultLoadingBlocks(state, action: PayloadAction<FetchStatusType>) {
            return { ...state, loadingBlocks: action.payload }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDashboardBlocks.pending, state => {
                return { ...state, loadingBlocks: FetchStatusType.LOADING }
            })
            .addCase(getDashboardBlocks.fulfilled, (state, action) => {
                return { ...state, loadingBlocks: FetchStatusType.COMPLETED, dashboardBlocks: action.payload }
            })
            .addCase(getDashboardBlocks.rejected, state => {
                return { ...state, loadingBlocks: FetchStatusType.FAILED }
            })

        builder
            .addCase(getBlockData.pending, (state, action) => {
                const blockId = parseInt(action.meta.arg.id)
                return {
                    ...state,
                    blockData: { ...state.blockData, [blockId]: null },
                    loadingBlockData: { ...state.loadingBlockData, [blockId]: FetchStatusType.LOADING }
                }
            })
            .addCase(getBlockData.fulfilled, (state, action) => {
                const blockId = parseInt(action.meta.arg.id)
                return {
                    ...state,
                    loadingBlockData: { ...state.loadingBlockData, [blockId]: FetchStatusType.COMPLETED },
                    blockData: { ...state.blockData, [blockId]: action.payload },
                    currentBlockId: parseInt(action.meta.arg.id),
                    currentBlockRequest: action.meta.arg.request
                }
            })
            .addCase(getBlockData.rejected, (state, action) => {
                const blockId = parseInt(action.meta.arg.id)
                return {
                    ...state,
                    loadingBlockData: { ...state.loadingBlockData, [blockId]: FetchStatusType.FAILED },
                    blockData: { ...state.blockData, [blockId]: null }
                }
            })

        builder
            .addCase(getCompanies.pending, state => {
                return { ...state, loadingCompanies: FetchStatusType.LOADING }
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                return { ...state, loadingCompanies: FetchStatusType.COMPLETED, companies: action.payload }
            })
            .addCase(getCompanies.rejected, state => {
                return { ...state, loadingCompanies: FetchStatusType.FAILED }
            })

        builder
            .addCase(getZones.pending, state => {
                return { ...state, loadingZones: FetchStatusType.LOADING }
            })
            .addCase(getZones.fulfilled, (state, action) => {
                return { ...state, loadingZones: FetchStatusType.COMPLETED, zones: action.payload }
            })
            .addCase(getZones.rejected, state => {
                return { ...state, loadingZones: FetchStatusType.FAILED }
            })

        builder
            .addCase(getDevicesAndPlayers.pending, state => {
                return { ...state, loadingDevicesPlayers: FetchStatusType.LOADING }
            })
            .addCase(getDevicesAndPlayers.fulfilled, (state, action) => {
                return { ...state, loadingDevicesPlayers: FetchStatusType.COMPLETED, devicesAndPlayers: action.payload }
            })
            .addCase(getDevicesAndPlayers.rejected, state => {
                return { ...state, loadingDevicesPlayers: FetchStatusType.FAILED }
            })

        builder
            .addCase(exportDashboardData.pending, state => {
                return { ...state, loadingExport: FetchStatusType.LOADING }
            })
            .addCase(exportDashboardData.fulfilled, state => {
                return { ...state, loadingExport: FetchStatusType.COMPLETED }
            })
            .addCase(exportDashboardData.rejected, state => {
                return { ...state, loadingExport: FetchStatusType.FAILED }
            })
    }
})

export const {
    setswitchInventoryRate,
    setswitchInventory,
    setSelectedCompaniesPerf,
    setPeriodBlock1,
    setRangeBlock1,
    setPeriodBlock2,
    setRangeBlock2,
    setPeriodBlock3,
    setRangeBlock3,
    setPeriodBlock4,
    setRangeBlock4,
    setPeriodBlock5,
    setRangeBlock5,
    setSortColumnBlock1,
    setSortColumnBlock2,
    setSortByBlock1,
    setSortByBlock2,
    setOrderBlock1,
    setOrderBlock2,
    setSwitchSeconde,
    setCompaniesChecked,
    setRowsPageBlock1,
    setPageBlock1,
    setDefaultLoadingBlocks,
    setRangeBlock6,
    setPeriodBlock6,
    setRowsPageBlock6,
    setPageBlock6,
    setSortColumnBlock6,
    setSortByBlock6,
    setOrderBlock6,
    setZonesChecked,
    setDevicesChecked,
    setPlayersChecked
} = Dashboard80Slice.actions

// Selectors
export const loadingBlocks = (state: RootState) => state.dashboard80.loadingBlocks

export const loadingBlockData = (state: RootState, blockId: number) => state.dashboard80.loadingBlockData[blockId]

export const loadingCompanies = (state: RootState) => state.dashboard80.loadingCompanies

export const loadingZones = (state: RootState) => state.dashboard80.loadingZones

export const loadingDevicesPlayers = (state: RootState) => state.dashboard80.loadingDevicesPlayers

export const loadingExport = (state: RootState) => state.dashboard80.loadingExport

export const dashboardBlocks = (state: RootState) => state.dashboard80.dashboardBlocks

export const blockData = (state: RootState, blockId: number) => state.dashboard80.blockData[blockId] || null

export const companies = (state: RootState) => state.dashboard80.companies

export const listZones = (state: RootState) => state.dashboard80.zones

export const devicesAndPlayers = (state: RootState) => state.dashboard80.devicesAndPlayers

export const currentBlockId = (state: RootState) => state.dashboard80.currentBlockId

export const currentBlockRequest = (state: RootState) => state.dashboard80.currentBlockRequest

export const switchInventoryRate = (state: RootState) => state.dashboard80.switchInventoryRate

export const switchInventory = (state: RootState) => state.dashboard80.switchInventory

export const selectedCompanies = (state: RootState) => state.dashboard80.selectedCompanies

export const periodBlock1 = (state: RootState) => state.dashboard80.periodId1

export const rangeBlock1 = (state: RootState) => state.dashboard80.rangeId1

export const periodBlock2 = (state: RootState) => state.dashboard80.periodId2

export const rangeBlock2 = (state: RootState) => state.dashboard80.rangeId2

export const periodBlock3 = (state: RootState) => state.dashboard80.periodId3

export const periodBlock6 = (state: RootState) => state.dashboard80.periodId6

export const rangeBlock3 = (state: RootState) => state.dashboard80.rangeId3

export const periodBlock4 = (state: RootState) => state.dashboard80.periodId4

export const rangeBlock4 = (state: RootState) => state.dashboard80.rangeId4

export const periodBlock5 = (state: RootState) => state.dashboard80.periodId5

export const rangeBlock5 = (state: RootState) => state.dashboard80.rangeId5

export const rangeBlock6 = (state: RootState) => state.dashboard80.rangeId6

export const sortById1 = (state: RootState) => state.dashboard80.sortByBlock1

export const sortById2 = (state: RootState) => state.dashboard80.sortByBlock2

export const orderId1 = (state: RootState) => state.dashboard80.orderBlock1

export const orderId2 = (state: RootState) => state.dashboard80.orderBlock2

export const sortColumnId1 = (state: RootState) => state.dashboard80.sortColumnBlock1

export const sortColumnId2 = (state: RootState) => state.dashboard80.sortColumnBlock2

export const sortById6 = (state: RootState) => state.dashboard80.sortByBlock6

export const orderId6 = (state: RootState) => state.dashboard80.orderBlock6

export const sortColumnId6 = (state: RootState) => state.dashboard80.sortColumnBlock6

export const switchSecond = (state: RootState) => state.dashboard80.switchSeconde

export const companiesChecked = (state: RootState) => state.dashboard80.companiesChecked

export const pageBlock1 = (state: RootState) => state.dashboard80.pageBlock1

export const rowsPageBlock1 = (state: RootState) => state.dashboard80.rowsPageBlock1

export const pageBlock6 = (state: RootState) => state.dashboard80.pageBlock6

export const rowsPageBlock6 = (state: RootState) => state.dashboard80.rowsPageBlock6

export const zonesChecked = (state: RootState) => state.dashboard80.zonesChecked

export const devicesChecked = (state: RootState) => state.dashboard80.devicesChecked

export const playersChecked = (state: RootState) => state.dashboard80.playersChecked

export default Dashboard80Slice.reducer
