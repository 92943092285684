import Http, { ApiRootType } from '../../Http/Http'
import { 
    BlockRequest,
    BlockResponse,
    CompaniesResponse,
    DashboardBlocksResponse,
    DevicesAndPlayersResponse,
    ZonesResponse
} from '../models/Dashboard80Model'

const http = new Http()

export class Dashboard80Service {
    static EXPORTS = "export"
    static BLOC_1 = 'bloc_1'
    static BLOC_2 = 'bloc_2'
    static BLOC_3 = 'bloc_3'
    static BLOC_4 = 'bloc_4'
    static BLOC_5 = 'bloc_5'
    static BLOC_6 = 'bloc_6'

    async getDashboardBlocks(): Promise<DashboardBlocksResponse> {
        try {
            const response = await http.get<DashboardBlocksResponse>(`performances/blocks`, undefined, ApiRootType.one)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getBlockData(id: string, request: BlockRequest): Promise<BlockResponse> {
        try {
            const cancelTokens: { [key: string]: string } = {
                '1': Dashboard80Service.BLOC_1,
                '2': Dashboard80Service.BLOC_2,
                '3': Dashboard80Service.BLOC_3,
                '4': Dashboard80Service.BLOC_4,
                '5': Dashboard80Service.BLOC_5,
                '6': Dashboard80Service.BLOC_6,
            };
    
            const cancelToken = cancelTokens[id as keyof typeof cancelTokens] || undefined

            const response = await http.post<BlockResponse>(
                `performances/blocks/${id}`, 
                request, 
                { cancelToken },
                ApiRootType.one)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getCompanies(): Promise<CompaniesResponse> {
        try {
            const response = await http.get<CompaniesResponse>(`performances/filters/company`, undefined, ApiRootType.one)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getZones(): Promise<ZonesResponse> {
        try {
            const response = await http.get<ZonesResponse>(`performances/filters/company/sites`, undefined, ApiRootType.one)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getDevicesAndPlayers(): Promise<DevicesAndPlayersResponse> {
        try {
            const response = await http.get<DevicesAndPlayersResponse>(`performances/filters/default`, undefined, ApiRootType.one)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async exportData(id: string, format: string, request: BlockRequest) {
        try {
            const response = await http.post(
                `performances/blocks/${id}/export/${format}`,
                request,
                { cancelToken: Dashboard80Service.EXPORTS, downloadFilename: `export.${format}` },
                ApiRootType.one
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    static abortAllBlock() {
        http.abortById(Dashboard80Service.BLOC_1)
        http.abortById(Dashboard80Service.BLOC_2)
        http.abortById(Dashboard80Service.BLOC_3)
        http.abortById(Dashboard80Service.BLOC_4)
        http.abortById(Dashboard80Service.BLOC_5)
        http.abortById(Dashboard80Service.BLOC_6)
    }
    static abortExport(){
        http.abortById(Dashboard80Service.EXPORTS)
    }
}
